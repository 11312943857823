.mapboxgl-canvas {
  width: 100%;
  height: 100%;
}

.map-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 14px 6px 18px;
  border-bottom: 1px dashed black;
  background-color: #eee;

  p {
    margin: 0;
    padding: 0;
  }

  .map-nav__select {
    margin-left: 14px;
  }
}

.map__wrap {
  display: flex;
  width: 100%;
  height: 100%;

  .mapboxgl-control-container {
    display: none;
  }
}

.print-map {
  width: 100%;
  height: 100%;
  background: url("../images/print_map.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom right;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.location-unknown-tooltip {
  margin: 0;
  padding: 7px 14px 6px 14px;
  border: 1px dashed black;
  background-color: rgba(0,0,0,0.45);
  background-color: rgba(255,255,255,0.45);
  backdrop-filter: blur(5px);
  color: black;
}
.location-unknown-tooltip span {
  text-shadow: 0px 0px 3px black;
}

@media screen and (max-width: 768px) {
  .map-nav {
    background-color: transparent;
  }
  .location-unknown-tooltip {
    margin-bottom: 140px;
  }
}