@import url('https://fonts.googleapis.com/css2?family=Amiri:ital,wght@0,400;0,700;1,400;1,700&display=swap');

html, body {
  font-size: 16px;
  font-family: 'Amiri', serif;
}

p,h1,h2,h3,h4,h5,h6,a,button {
  font-family: 'Amiri', serif;
  margin: 6px 0px;
  font-size: 1rem;
}

p {
  font-size: 1rem;
  color: #000;
  text-align: justify;
}
h1 {
  font-size: 2.2rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.8rem;
}
h4 {
  font-size: 1.6rem;
}
h5 {
  font-size: 1.4rem;
}
h6 {
  font-size: 1.2rem;
}

a, a * {
  text-decoration: none;
}
a:hover,
a:focus,
a:visited,
a:hover *,
a:focus *,
a:visited * {
  text-decoration: none;
  color: blue;
}

.no-list-style {
  list-style: none;
  ul,ol { list-style: none; }
}

ul {
  margin-top: 0;
}

p img {
  width: 100%;
  height: auto;
}

.bold {
  font-weight: bold;
}
