.saint-listing__wrap {
  // ...
  overflow-y: scroll;
  height: calc(100% - 42px);
}
.saint-details__wrap {
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  overflow-y: scroll;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  background-color: #fff;
  padding-top: 40px;
}
.saint-details__controls {
  position: absolute;
  top: 0; right: 0; left: 0;
  display: flex;
  align-items: center;
  border-bottom: 1px dashed black;
  cursor: pointer;
}
.saint-details__controls p {
  display: flex;
  flex: 1;
  margin: 0;
  padding: 8px 14px 6px 18px;

  &:hover {
    background-color: #eee;
  }
}
.saint-details__controls .view-on-map-toggle {
  display: none;
}
.saint-details__controls p:last-child {
  border-left: 1px dashed black;
}
.saint-details__controls-icon {
  width: 40px;
  height: auto;
}


.saint-wrap {
  padding: 5px 20px 0px 10px;
  cursor: pointer;
  border-bottom: 1px dashed #000;
  &:last-child {
    border-bottom: 0;
  }

  &.selected {
    padding-top: 8px;
  }

  &:hover {
    background-color: #eee;
  }
  &.inactive:hover {
    background-color: #fff;
  }
  &.inactive {
    cursor: auto;
  }

  .name {
    padding: 5px 0 0 0;
    margin: 0;
  }
  .preview-text {
    padding-left: 36px;
    p { margin-top: 2px; }
  }
  .body {
    display: none;
    padding-left: 36px;
  }


  &.selected {
    .body {
      display: block;
    }
  }
}

.ar .saint-wrap .preview-text {
  padding-left: 8px;
  padding-right: 28px;
}

.ar .saint-wrap .body {
  padding-right: 28px;
}

// .name {
//   padding: 5px 0 0 0;
//   margin: 0;
// }
// .preview-text {
//   padding-left: 30px;
//   p { margin-top: 2px; }
// }
// .body {
//   display: none;
//   padding-left: 20px;
// }


// &.selected {
//   .body {
//     display: block;
//   }
// }
// }

@media screen and (max-width: 768px) {
  .saint-details__controls .view-on-map-toggle {
    display: flex;
  }
}