.app-wrap__outer {
  width: 100vw;
  height: 100vh;
}

.footer-shim {
  height: 200px;
}

.app-wrap__inner {
  display: flex;
  width: 100%;
  height: 94vh;
}

.side-menu__wrap {
  position: relative;
  width: 38%;
  height: 100%;
  border-right: 1px dashed #000;
}

.page-wrap {
  position: relative;
  width: 62%;
  height: 100%;

}

@media screen and (max-width: 920px) {
  .side-menu__wrap {
    width: 45%;
  }
  .page-wrap {
    width: 55%;
  }
}
@media screen and (max-width: 768px) {
  .app-wrap__outer {
    width: 100vw;
    height: 100vh;
  }
  .app-wrap__inner {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  .side-menu__wrap {
    width: 100%;
  }
  .page-wrap {
    // display: none;
    visibility: hidden;
    position: absolute;
    top: 117px;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: calc(100% - 117px);
  }
  .page-wrap.show-mobile-map {
    // display: inline-block;
    visibility: visible;
    // border: 1px solid red;
  }
}



body,
div#root,
div.app-wrap__outer,
div.app-wrap__inner,
div.side-menu__wrap {
  overflow: hidden;
}