@import 'base/all';
@import 'components/all';
@import 'pages/all';

// Enter overwrites here if needed
html, body {
  margin: 0;
  width: 100%;
  height: 100%;
}

body.ar .rtl {
  direction: rtl;
}