.about__wrap {
  position: relative;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  overflow: scroll;
}

.about__wrap-inner {
  position: relative;
  width: 60%;
  max-width: 670px;
  padding: 30px 0px;
  margin: 0 auto;
}

.about-background-sketch__outer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.about-background-sketch__inner {
  width: 50%;
  position: relative;
}

@media screen and (max-width: 1200px) {
  .about-background-sketch__inner {
    width: 75vw;
    height: 75vw;
    margin-left: 12.5vw;
  }
}

@media screen and (max-width: 900px) {
  .about-background-sketch__inner {
    width: 110vw;
    // height: 97.5vw;
    margin-left: -3.5vw;
  }

  .about__wrap-inner {
    width: 100%;
    max-width: 90%;
  }
}


@media screen and (max-width: 768px) {
  .about__wrap {
    width: 100%;
    margin-left: 0;
  }
  .about__wrap-inner {
    // padding: 20px;
  }
}
