@import url("https://fonts.googleapis.com/css2?family=Amiri:ital,wght@0,400;0,700;1,400;1,700&display=swap");
.app-wrap__outer {
  width: 100vw;
  height: 100vh;
}

.footer-shim {
  height: 200px;
}

.app-wrap__inner {
  display: flex;
  width: 100%;
  height: 94vh;
}

.side-menu__wrap {
  position: relative;
  width: 38%;
  height: 100%;
  border-right: 1px dashed #000;
}

.page-wrap {
  position: relative;
  width: 62%;
  height: 100%;
}

@media screen and (max-width: 920px) {
  .side-menu__wrap {
    width: 45%;
  }
  .page-wrap {
    width: 55%;
  }
}
@media screen and (max-width: 768px) {
  .app-wrap__outer {
    width: 100vw;
    height: 100vh;
  }
  .app-wrap__inner {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  .side-menu__wrap {
    width: 100%;
  }
  .page-wrap {
    visibility: hidden;
    position: absolute;
    top: 117px;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: calc(100% - 117px);
  }
  .page-wrap.show-mobile-map {
    visibility: visible;
  }
}
body,
div#root,
div.app-wrap__outer,
div.app-wrap__inner,
div.side-menu__wrap {
  overflow: hidden;
}

html, body {
  font-size: 16px;
  font-family: "Amiri", serif;
}

p, h1, h2, h3, h4, h5, h6, a, button {
  font-family: "Amiri", serif;
  margin: 6px 0px;
  font-size: 1rem;
}

p {
  font-size: 1rem;
  color: #000;
  text-align: justify;
}

h1 {
  font-size: 2.2rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.8rem;
}

h4 {
  font-size: 1.6rem;
}

h5 {
  font-size: 1.4rem;
}

h6 {
  font-size: 1.2rem;
}

a, a * {
  text-decoration: none;
}

a:hover,
a:focus,
a:visited,
a:hover *,
a:focus *,
a:visited * {
  text-decoration: none;
  color: blue;
}

.no-list-style {
  list-style: none;
}
.no-list-style ul, .no-list-style ol {
  list-style: none;
}

ul {
  margin-top: 0;
}

p img {
  width: 100%;
  height: auto;
}

.bold {
  font-weight: bold;
}

.about__wrap {
  position: relative;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  overflow: scroll;
}

.about__wrap-inner {
  position: relative;
  width: 60%;
  max-width: 670px;
  padding: 30px 0px;
  margin: 0 auto;
}

.about-background-sketch__outer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.about-background-sketch__inner {
  width: 50%;
  position: relative;
}

@media screen and (max-width: 1200px) {
  .about-background-sketch__inner {
    width: 75vw;
    height: 75vw;
    margin-left: 12.5vw;
  }
}
@media screen and (max-width: 900px) {
  .about-background-sketch__inner {
    width: 110vw;
    margin-left: -3.5vw;
  }
  .about__wrap-inner {
    width: 100%;
    max-width: 90%;
  }
}
@media screen and (max-width: 768px) {
  .about__wrap {
    width: 100%;
    margin-left: 0;
  }
}
.header-placeholder {
  height: 6vh;
  min-height: 38px;
}

.header-wrap__outer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 6vh;
  min-height: 38px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-bottom: 1px dashed #3d3d3d;
  z-index: 9;
}

.header-wrap__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 0 20px;
  width: 100%;
}
.header-wrap__inner a {
  color: black !important;
}
.header-wrap__inner a:hover {
  text-decoration: underline;
  text-decoration-style: dashed;
}
.header-wrap__inner a.selected {
  text-decoration: underline;
  text-decoration-style: solid;
}

.header__lang-selector {
  background-color: transparent;
  border: 0;
  padding: 10px;
  margin: 0;
  cursor: pointer;
}

.header__lang-selector:hover {
  text-decoration: underline;
  text-decoration-style: dashed;
}

.header__lang-selector.selected {
  text-decoration: underline;
  text-decoration-style: solid;
}

.header-wrap__inner.mobile {
  display: none;
}

@media screen and (max-width: 768px) {
  .header-wrap__inner {
    display: none;
  }
  .header-placeholder {
    height: 74px;
  }
  .header-wrap__outer {
    height: 74px;
  }
  .header-wrap__inner.mobile {
    position: relative;
    display: flex;
    flex-direction: row;
  }
  .header__lang-selectors span {
    margin: 0 6px !important;
  }
  .header-wrap__inner.mobile .header__lang-selector {
    padding: 0;
  }
  .header-wrap__inner.mobile .nav-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
  }
  .header-wrap__inner.mobile .title-wrap {
    display: flex;
    align-items: center;
  }
  .header-wrap__inner.mobile .title-wrap p,
  .header-wrap__inner.mobile .nav-wrap p {
    margin: 0 !important;
  }
}
.mapboxgl-canvas {
  width: 100%;
  height: 100%;
}

.map-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 14px 6px 18px;
  border-bottom: 1px dashed black;
  background-color: #eee;
}
.map-nav p {
  margin: 0;
  padding: 0;
}
.map-nav .map-nav__select {
  margin-left: 14px;
}

.map__wrap {
  display: flex;
  width: 100%;
  height: 100%;
}
.map__wrap .mapboxgl-control-container {
  display: none;
}

.print-map {
  width: 100%;
  height: 100%;
  background: url("../images/print_map.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom right;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.location-unknown-tooltip {
  margin: 0;
  padding: 7px 14px 6px 14px;
  border: 1px dashed black;
  background-color: rgba(0, 0, 0, 0.45);
  background-color: rgba(255, 255, 255, 0.45);
  backdrop-filter: blur(5px);
  color: black;
}

.location-unknown-tooltip span {
  text-shadow: 0px 0px 3px black;
}

@media screen and (max-width: 768px) {
  .map-nav {
    background-color: transparent;
  }
  .location-unknown-tooltip {
    margin-bottom: 140px;
  }
}
.saint-listing__wrap {
  overflow-y: scroll;
  height: calc(100% - 42px);
}

.saint-details__wrap {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: scroll;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  background-color: #fff;
  padding-top: 40px;
}

.saint-details__controls {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  border-bottom: 1px dashed black;
  cursor: pointer;
}

.saint-details__controls p {
  display: flex;
  flex: 1;
  margin: 0;
  padding: 8px 14px 6px 18px;
}
.saint-details__controls p:hover {
  background-color: #eee;
}

.saint-details__controls .view-on-map-toggle {
  display: none;
}

.saint-details__controls p:last-child {
  border-left: 1px dashed black;
}

.saint-details__controls-icon {
  width: 40px;
  height: auto;
}

.saint-wrap {
  padding: 5px 20px 0px 10px;
  cursor: pointer;
  border-bottom: 1px dashed #000;
}
.saint-wrap:last-child {
  border-bottom: 0;
}
.saint-wrap.selected {
  padding-top: 8px;
}
.saint-wrap:hover {
  background-color: #eee;
}
.saint-wrap.inactive:hover {
  background-color: #fff;
}
.saint-wrap.inactive {
  cursor: auto;
}
.saint-wrap .name {
  padding: 5px 0 0 0;
  margin: 0;
}
.saint-wrap .preview-text {
  padding-left: 36px;
}
.saint-wrap .preview-text p {
  margin-top: 2px;
}
.saint-wrap .body {
  display: none;
  padding-left: 36px;
}
.saint-wrap.selected .body {
  display: block;
}

.ar .saint-wrap .preview-text {
  padding-left: 8px;
  padding-right: 28px;
}

.ar .saint-wrap .body {
  padding-right: 28px;
}

@media screen and (max-width: 768px) {
  .saint-details__controls .view-on-map-toggle {
    display: flex;
  }
}
html, body {
  margin: 0;
  width: 100%;
  height: 100%;
}

body.ar .rtl {
  direction: rtl;
}

