.header-placeholder {
  height: 6vh;
  min-height: 38px;
}
.header-wrap__outer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 6vh;
  min-height: 38px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: #fff;
  border-bottom: 1px dashed #3d3d3d;

  z-index: 9;
}

.header-wrap__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 0 20px;
  width: 100%;

  a {
    color: black !important;
  }

  a:hover {
    text-decoration: underline;
    text-decoration-style: dashed;
  }
  a.selected {
    text-decoration: underline;
    text-decoration-style: solid;
  }
}

.header__lang-selector {
  background-color: transparent;
  border: 0;
  padding: 10px;
  margin: 0;
  cursor: pointer;
}
.header__lang-selector:hover {
  text-decoration: underline;
  text-decoration-style: dashed;
}
.header__lang-selector.selected {
  text-decoration: underline;
  text-decoration-style: solid;
}

.header-wrap__inner.mobile {
  display: none;
}
@media screen and (max-width: 768px) {
  .header-wrap__inner {
    display: none;
  }
  .header-placeholder {
    height: 74px;
  }
  .header-wrap__outer {
    height: 74px;
  }
  .header-wrap__inner.mobile {
    position: relative;
    display: flex;
    flex-direction: row;
  }
  .header__lang-selectors span {
    margin: 0 6px !important;
  }
  .header-wrap__inner.mobile .header__lang-selector {
    padding: 0;
  }
  .header-wrap__inner.mobile .nav-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;

  }
  .header-wrap__inner.mobile .title-wrap {
    display: flex;
    align-items: center;
  }
  .header-wrap__inner.mobile .title-wrap p,
  .header-wrap__inner.mobile .nav-wrap p {
    margin: 0 !important;
  }
}